<template>
	<div class="map">
		<div class="google-map" ref="googleMap" :style="containerStyle"></div>
		<template v-if="Boolean(this.google) && Boolean(this.map)">
			<slot
				:google="google"
				:map="map"
			/>
		</template>
	</div>
</template>

<script>
	import GoogleMapsApiLoader from 'google-maps-api-loader';

	export default {
		name: 'GoogleMap',
		data() {
			return {
				google: null,
				map: null
			}
		},
		computed: {
			containerStyle(){
				return this.containerStyles;
			}
		},
		methods: {
			initializeMap() {
				this.map = new this.google.maps.Map(
					this.$refs.googleMap, this.mapConfig
				);

				if(this.bounds != null && this.bounds.length > 0){

					// if the map contains multiple markers or map polygons
					// we need to create the bounds of the area that should be contained in the map
					let bounds = new google.maps.LatLngBounds();

					this.bounds.forEach(latLng => {
						bounds.extend(latLng);
					});
	
					let padding = 20;
					let paddingY = 20;
					
					this.map.fitBounds(bounds,{
						top:padding,
						right:padding,
						left:padding+this.overlayW,
						bottom:padding
					});

				}else{
					// If no bounds are defined, we need to offset the center of the map
					// to compensate for the contentbox to the left

					// Panning only works on initial page load if not in a setTimeout.
					setTimeout(() => {
						this.map.panBy(this.offsetX, 0);
					}, 1);
				}

			}
		},
		mounted() {
			let params = {apiKey: process.env.VUE_APP_GOOGLE_MAPS_KEY}
			
			if(this.libraries.length !== 0)
				params.libraries = this.libraries;
			
			GoogleMapsApiLoader(params)
			.then(response => {
				this.google = response;
				this.initializeMap();
			});
		},
		props: {
			libraries: {
				type: Array,
				default: []
			},
			mapConfig: Object,
			width: {
				type: Number,
				default: 500
			},
			height: {
				type: Number,
				default: 300
			},
			containerStyles: {
				type: String,
				default: 'width: 500px; height: 300px'
			},
			offsetX:{
				type: Number,
				default: 0
			},
			overlayW:{
				type: Number,
				default: 0
			},
			bounds:{
				type: Array,
				default: null
			}
		},
	}
</script>

<style scoped>
	.map {
		flex: 2;
		position: relative;
		background: #DDD;
		min-height: 360px;
		box-shadow: 0 20px 40px -20px rgba(0,0,0,.4);
		border: 1px solid #545556;
		border-radius: 3px;
		min-height: 374px;
		overflow: hidden;
	}
</style>
